import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory, useLocation } from "react-router-dom";
import {apiConfig, axiosInstance} from "../../apiConfig";

import { Network } from '@capacitor/network';

import './Request.css'

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Viewprofile: React.FC = () => {
  const history = useHistory();
  const inputRef = useRef(null);
  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);
  const [displayPicture, setDisplayPicture] = useState('');

  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [inputFirstName, setInputFirstName] = useState(localStorage.getItem("name") || '');
  const latestInputFirstNameValue = useRef('');

  const latestInputTradingNamePatternValue = useRef('');
  const [inputTradingName, setInputTradingNamePattern] = useState(localStorage.getItem("tradingName") || '');

  const [inputPhoneNo, setInputPhoneNoPattern] = useState(localStorage.getItem("mobile") || '');
  const [inputEmail, setInputEmailPattern] = useState(localStorage.getItem("email") || '');
  const latestInputPhoneNoPatternValue = useRef('');
  const latestInputEmailPatternValue = useRef('');

  const [roles, setRoles] = useState<Array<any>>([]);
  const [selectedRole, setSelectedRole] = useState(0);

  const [businessDropdown, setBusinessDropdown] = useState<Array<any>>([]);
  const [postcodeList, setPostcodeList] = useState<Array<any>>([]);
  const [postcodeError, setPostcodeError] = useState('');
  const [serviceAssigned, setServiceAssigned] = useState<Array<any>>([]);
  const [selectedBD, setSelectedBD] = useState(0);
  const [PClong, setPClong] = useState('');
  const [PClat, setPClat] = useState('');
  const [inputLBname, setInputLBname] = useState('');
  const latestInputLBnameValue = useRef('');

  const [inputLBtradeName, setInputLBtradeName] = useState('');
  const latestInputLBtradenameValue = useRef('');

  const [inputCompanyNo, setInputCompanyNo] = useState('');
  const latestInputCompanyNoValue = useRef('');

  const [inputBemail, setInputBemail] = useState('');
  const latestInputBemailValue = useRef('');

  const [inputBwebsite, setInputBwebsite] = useState('');
  const latestInputBwebsiteValue = useRef('');

  const [inputBnumber, setInputBnumber] = useState('');
  const latestInputBnumberValue = useRef('');

  const [inputAddressOne, setInputAddressOne] = useState('');
  const latestInputAddressOneValue = useRef('');

  const [inputAddressTwo, setInputAddressTwo] = useState('');
  const latestInputAddressTwoValue = useRef('');

  const [inputTown, setInputTown] = useState('');
  const latestInputTownValue = useRef('');

  const [inputCountry, setInputCountry] = useState('');
  const latestInputCountryValue = useRef('');


  const [blurDropDown, setBlurDropDown] = useState(false);


  const [isYesChecked, setIsYesChecked] = useState(false);
  const [isNoChecked, setIsNoChecked] = useState(false);

  const [formState, setFormState] = useState({
    firstName: '',
    firstNameError: '',
    tradingName: '',
    tradingNameError: '',
    phoneNo: '',
    phoneNoError: '',
    email: '',
    emailError: '',
    LBname: '',
    LBnameError: '',
    LBtradeName: '',
    LBtradeNameError: '',
    companyNo: '',
    companyNoError: '',
    bEmail: '',
    bEmailError: '',
    bWebsite: '',
    bWebsiteError: '',
    bNumber: '',
    bNumberError: '',
    addressOne: '',
    addressOneError: '',
    addressTwo: '',
    addressTwoError: '',
    countryError: '',
    country: '',
    townError: '',
    town: ''
  });
  const goBack = () => {
    history.goBack();
    focusOut();
  };

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);


  const [networkStatus, setNetworkStatus] = useState<any>(true);

  const [phoneNumber, setPhoneNumber] = useState("");


  useEffect(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    }
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  useIonViewWillEnter(() => {
    getUserDetails();
    getRoledropdown();
    getBusinessTypeDropdown();
    getlegalinformation();
    getServiceCategoryAssigned();
    getLegalBusinessdetails();
    setDisplayPicture(`${localStorage.getItem("displaypicture")}`);
    console.log("darkModeValue", localStorage.getItem("darkMode"));
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, [])

  const getUserDetails = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/userdetails"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if(response.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          console.log("response", response.data.data);
          if (response.data.status == true) {
            // setLoading(false);
            // setBackdropVisible(false);

            localStorage.setItem("mobile",response.data.data.mobile);
            localStorage.setItem("email",response.data.data.email);
            latestInputEmailPatternValue.current = response.data.data.email;
            latestInputPhoneNoPatternValue.current = response.data.data.mobile;
            latestInputTradingNamePatternValue.current = response.data.data.lastname;
            localStorage.setItem("tradingName",response.data.data.lastname)
            setInputTradingNamePattern(response.data.data.lastname);
            localStorage.setItem("name", response.data.data.name)
            latestInputFirstNameValue.current = response.data.data.name;
            setSelectedRole(response.data.data.role);
            setInputFirstName(response.data.data.name);
            setInputPhoneNoPattern(response.data.data.mobile);
            setInputEmailPattern(response.data.data.email);
          } else {
            // setLoading(false);
            console.log("working");

            // setBackdropVisible(false);
          }
        }
      }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }

  const handleYesCheckboxChange = (event: any) => {
    setIsYesChecked(event.target.checked);
  };
  const handleNoCheckboxChange = (event: any) => {
    setIsYesChecked(event.target.checked);
  };

  const getRoledropdown = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/roles"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          console.log("response", response.data.roles);
          setRoles(response.data.roles);
          // setLoading(false);
          // setBackdropVisible(false);
        }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }

  const getlegalinformation = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/legalinformation"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          if (response.data.status == true) {
            if (response.data.data.license_status == "no") {
              setIsYesChecked(false);
              setIsNoChecked(true);
              // setLoading(false);
              // setBackdropVisible(false);
            } else {
              setIsYesChecked(true);
              setIsNoChecked(false);
              // setLoading(false);
              // setBackdropVisible(false);
            }

          } else {
            console.log("working");
            // setLoading(false);
            // setBackdropVisible(false);
          }

        }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }

  const getServiceCategoryAssigned = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/servicecategory"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          console.log("response", response.data.services);
          setServiceAssigned(response.data.services);
          // setLoading(false);
          // setBackdropVisible(false);
        }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }

  const getBusinessTypeDropdown = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/business-types"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          console.log("response", response.data.business_types);
          setBusinessDropdown(response.data.business_types);
          // setLoading(false);
          // setBackdropVisible(false);
        }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }
  const getLegalBusinessdetails = () => {
    // setLoading(true);
    // setBackdropVisible(true);
    const endpoint = "/legalBusinessdetails"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          // setLoading(false);
          // setBackdropVisible(false);
        } else {
          if (response.data.status == true) {
            setInputLBname(response.data.data.legal_business_name);
            setInputLBtradeName(response.data.data.trading_name);
            setInputCompanyNo(response.data.data.company_number);
            setInputBemail(response.data.data.business_email);
            setInputBwebsite(response.data.data.business_website);
            setInputBnumber(response.data.data.phone_number);
            setInputAddressOne(response.data.data.postcode);
            setPClong(response.data.data.lng);
            setPClat(response.data.data.lat);
            setInputAddressTwo('');
            setInputTown(response.data.data.city);
            setInputCountry(response.data.data.country);
            latestInputLBnameValue.current = response.data.data.legal_business_name;
            latestInputLBtradenameValue.current = response.data.data.trading_name;
            latestInputCompanyNoValue.current = response.data.data.company_number;
            latestInputBemailValue.current = response.data.data.business_email;
            latestInputBnumberValue.current = response.data.data.phone_number;
            latestInputAddressOneValue.current = response.data.data.postcode;
            latestInputAddressTwoValue.current = '';
            latestInputTownValue.current = response.data.data.city;
            latestInputCountryValue.current = response.data.data.country;
            latestInputBwebsiteValue.current = response.data.data.business_website;
            setSelectedBD(JSON.parse(response.data.data.business_type));
            // setLoading(false);
            // setBackdropVisible(false);
          } else {
            // setLoading(false);
            // setBackdropVisible(false);
          }

        }
        // setTimeout(() => {
        //   if (loading == true) {
        //     setLoading(false);
        //     setBackdropVisible(false);
        //     // setErrorMessage("Something went wrong on API please try again");
        //     // setShowToast(true);
        //   }
        // }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        // setLoading(false);
        // setBackdropVisible(false);
      });
  }
  const handleFirstNameInputChanges = (event: any) => {
    const firstName = event.target.value;
    setInputFirstName(event.target.value);
    latestInputFirstNameValue.current = firstName;
    // const firstNamePattern = /^[a-zA-Z]+ [a-zA-Z]+$/;
    // if (firstName && !firstNamePattern.test(firstName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     firstNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     firstNameError: ''
    //   }));
    // }
  };
  const handleTradingNameInputChanges = (event: any) => {
    const tradingName = event.target.value;
    setInputTradingNamePattern(event.target.value);
    latestInputTradingNamePatternValue.current = tradingName;
    // const tradingNamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (tradingName && !tradingNamePattern.test(tradingName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     tradingNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     tradingNameError: ''
    //   }));
    // }
  };
  const handlePhoneNoInputChanges = (event: any) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
    setInputPhoneNoPattern(numericValue); // Update the input with only numeric value
    latestInputPhoneNoPatternValue.current = numericValue; // Update the latest value
  
    if (inputValue !== numericValue) {
      setFormState(prevState => ({
        ...prevState,
        phoneNoError: 'Only numbers are allowed'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        phoneNoError: ''
      }));
    }
  };
  const handleEmailInputChanges = (event: any) => {
    const email = event.target.value;
    setInputEmailPattern(event.target.value);
    latestInputEmailPatternValue.current = email;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (email && !emailPattern.test(email)) {
      setFormState(prevState => ({
        ...prevState,
        emailError: 'Invalid email address'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        emailError: ''
      }));
    }
  }
  const submitUserDetails = () => {
    if (formState.emailError == '' && formState.firstNameError == '' && formState.phoneNoError == '' && formState.tradingNameError == '') {
      if (!latestInputEmailPatternValue.current || !latestInputPhoneNoPatternValue.current || !latestInputTradingNamePatternValue.current || selectedRole == 0 || !latestInputFirstNameValue.current) {
        setErrorMessage('Please enter a All fields');
        setShowErrorToast(true);
      } else {
        setLoading(true);
        setBackdropVisible(true);
        const endpoint = "/userdetails"
         axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
          "name": latestInputFirstNameValue.current,
          "lastname": latestInputTradingNamePatternValue.current,
          "mobile": latestInputPhoneNoPatternValue.current,
          "email": latestInputEmailPatternValue.current,
          "role": selectedRole,
          "update_info": "U"
        }, { headers: apiConfig.getHeaders(endpoint) })
          .then(response => {
            if (response.data.suspendstatus == false) {
              setSupendedMsg(response.data.message);
              setSupendedTitle(response.data.title);
              setShowSuspendedModal(true);
              setLoading(false);
              setBackdropVisible(false);
            } else {
              if (response.data.status == true) {
                localStorage.setItem("name", latestInputFirstNameValue.current);
                localStorage.setItem("tradingName", latestInputTradingNamePatternValue.current);
                localStorage.setItem("email", latestInputEmailPatternValue.current);
                setSuccessMessage(response.data.message);
                setShowToastSuccess(true);
                setLoading(false);
                setBackdropVisible(false);
              } else {
                setErrorMessage(response.data.message);
                setShowErrorToast(true);
                setLoading(false);
                setBackdropVisible(false);
              }
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch(error => {
            console.log(error);
            if (error.code == "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });
      }
    } else {
      setErrorMessage('Please enter a valid details');
      setShowErrorToast(true);
    }
  }
  const handleLBnameInputChanges = (event: any) => {
    const LBname = event.target.value;
    setInputLBname(event.target.value);
    latestInputLBnameValue.current = LBname;
    // const LBnamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (LBname && !LBnamePattern.test(LBname)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBnameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBnameError: ''
    //   }));
    // }
  }

  const handleLBtradeNameInputChanges = (event: any) => {
    const LBtradeName = event.target.value;
    setInputLBtradeName(event.target.value);
    latestInputLBtradenameValue.current = LBtradeName;
    // const LBtradeNamePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (LBtradeName && !LBtradeNamePattern.test(LBtradeName)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBtradeNameError: 'Invalid Name'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     LBtradeNameError: ''
    //   }));
    // }
  }

  const handleCompanyNoInputChanges = (event: any) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
    setInputCompanyNo(numericValue); // Update the input value
    latestInputCompanyNoValue.current = numericValue; // Update the latest value
  
    if (inputValue !== numericValue) {
      setFormState(prevState => ({
        ...prevState,
        companyNoError: 'Only numbers are allowed'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        companyNoError: ''
      }));
    }
  };

  const handleBemailInputChanges = (event: any) => {
    const bEmail = event.target.value;
    setInputBemail(event.target.value);
    latestInputBemailValue.current = bEmail;
    const bEmailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (bEmail && !bEmailPattern.test(bEmail)) {
      setFormState(prevState => ({
        ...prevState,
        bEmailError: 'Invalid email address'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        bEmailError: ''
      }));
    }
  }
  const handleBwebsiteInputChanges = (event: any) => {
    const bWebsite = event.target.value;
    setInputBwebsite(event.target.value);
    latestInputBwebsiteValue.current = bWebsite;
    // const bWebsitePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (bWebsite && !bWebsitePattern.test(bWebsite)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     bWebsiteError: 'Invalid Website'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     bWebsiteError: ''
    //   }));
    // }
  }
  const handleBnumberInputChanges = (event: any) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
    setInputBnumber(numericValue); // Update the input with only numeric value
    latestInputBnumberValue.current = numericValue; // Update the latest value
  
    if (inputValue !== numericValue) {
      setFormState(prevState => ({
        ...prevState,
        bNumberError: 'Only numbers are allowed'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        bNumberError: ''
      }));
    }
  };

  const getPostCode = (postcode: any) => {
    // setLoading(true);
    // setBackdropVisible(true);
    if (postcode) {
      setPostcodeError('');
       axiosInstance.get(`https://api.postcodes.io/postcodes?q=${postcode}&format=json`)
        .then(response => {
          console.log("response", response);
          setPostcodeList(response.data.result)
        })
        .catch(error => {
          console.log(error);
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
        });
    } else {
      setPostcodeList([]);
      setPostcodeError("Please Enter a valid postcode")
    }
  }
  const focusOut = () => {
    setBlurDropDown(false);
  }
  const handleAddressOneInputChanges = (event: any) => {
    latestInputAddressOneValue.current = '';
    setPClong('');
    setPClat('');
    setInputAddressOne(event.target.value);
    getPostCode(event.target.value);
    setBlurDropDown(true);

    // const addressOnePattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
    // if (addressOne && !addressOnePattern.test(addressOne)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     addressOneError: 'Invalid Address'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     addressOneError: ''
    //   }));
    // }
  }

  const handleOptionClick = (option: string) => {
    setBlurDropDown(true);
    console.log("option", option);
    setInputAddressOne(option);
    latestInputAddressOneValue.current = option
  };

  // const handleAddressTwoInputChanges = (event: any) => {
  //   const addressTwo = event.target.value;
  //   setInputAddressTwo(event.target.value);
  //   latestInputAddressTwoValue.current = addressTwo;
  //   const addressTwoPattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
  //   if (addressTwo && !addressTwoPattern.test(addressTwo)) {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       addressTwoError: 'Invalid Address'
  //     }));
  //   } else {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       addressTwoError: ''
  //     }));
  //   }
  // }

  // const handleTownInputChanges = (event: any) => {
  //   const town = event.target.value;
  //   setInputTown(event.target.value);
  //   latestInputTownValue.current = town;
  //   const TownPattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
  //   if (town && !TownPattern.test(town)) {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       townError: 'Invalid Town'
  //     }));
  //   } else {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       townError: ''
  //     }));
  //   }
  // }

  // const handleCountryInputChanges = (event: any) => {
  //   const country = event.target.value;
  //   setInputCountry(event.target.value);
  //   latestInputCountryValue.current = country;
  //   const countryPattern = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;
  //   if (country && !countryPattern.test(country)) {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       countryError: 'Invalid Country'
  //     }));
  //   } else {
  //     setFormState(prevState => ({
  //       ...prevState,
  //       countryError: ''
  //     }));
  //   }
  // }

  const submitLegalDetails = () => {
    if (latestInputAddressOneValue.current == '') {
      setErrorMessage('Please select a Postcode from list');
      setShowErrorToast(true);
    } else {
      if (formState.LBnameError == '' && formState.townError == '' && formState.countryError == '' && formState.addressOneError == '' && formState.bNumberError == '' && formState.bWebsiteError == '' && formState.bEmailError == '' && formState.companyNoError == '' && formState.LBtradeNameError == '') {
        if (!latestInputLBnameValue.current || !latestInputLBtradenameValue.current || !latestInputCompanyNoValue.current || selectedBD == 0 || !latestInputBemailValue.current || !latestInputBwebsiteValue.current || !latestInputBnumberValue.current || !latestInputAddressOneValue.current) {
          setErrorMessage('Please enter a All fields');
          setShowErrorToast(true);
        } else {
          setLoading(true);
          setBackdropVisible(true);
          const endpoint = "/legalBusinessdetails"
           axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
            companyname: latestInputLBnameValue.current,
            owner_name: latestInputLBtradenameValue.current,
            business_type: selectedBD,
            company_no: latestInputCompanyNoValue.current,
            business_email: latestInputBemailValue.current,
            business_website: latestInputBwebsiteValue.current,
            phone1: latestInputBnumberValue.current,
            address: latestInputAddressOneValue.current,
            city: latestInputTownValue.current,
            country: latestInputCountryValue.current,
            longitude: PClong,
            latitude: PClat,
            "update_info": "U"
          }, { headers: apiConfig.getHeaders(endpoint) })
            .then(response => {
              if (response.data.suspendstatus == false) {
                setSupendedMsg(response.data.message);
                setSupendedTitle(response.data.title);
                setShowSuspendedModal(true);
                setLoading(false);
                setBackdropVisible(false);
              } else {
                if (response.data.status == true) {
                  setSuccessMessage(response.data.message);
                  setShowToastSuccess(true);
                  setLoading(false);
                  setBackdropVisible(false);
                } else {
                  setErrorMessage(response.data.message);
                  setShowErrorToast(true);
                  setLoading(false);
                  setBackdropVisible(false);
                }
              }
              setTimeout(() => {
                if (loading == true) {
                  setLoading(false);
                  setBackdropVisible(false);
                  // setErrorMessage("Something went wrong on API please try again");
                  // setShowToast(true);
                }
              }, 10000);
            })
            .catch(error => {
              console.log(error);
              if (error.code == "ERR_NETWORK") {
                setNetworkStatus(false);
              } else {
                setNetworkStatus(true);
              }
              setLoading(false);
              setBackdropVisible(false);
            });
        }
      } else {
        setErrorMessage('Please enter a valid details');
        setShowErrorToast(true);
      }
    }
  }

  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="no-network-div center-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile dark-profile">
            <div className="center-div">
              <div className="background-holder"></div>
              <div onClick={focusOut} className="profile-top-card dark-profile-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonLabel className="dark-title">User / Login Details</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputFirstName}
                          onChange={e => handleFirstNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">First Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.firstNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.firstNameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputTradingName}
                          onChange={e => handleTradingNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Surname<span className="span-star-clr">*</span></label>
                      </div>
                    </IonCol>
                    
                    <IonCol size="12" col-12>
      <div className="model-container">
        <label className="dark-label-outline-model">
          Phone Number<span className="span-star-clr">*</span>
        </label>
        <PhoneInput
          country={"gb"} // Default to UK (+44)
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
          inputClass="dark-form-control-model fontName"
          containerClass="intl-tel-input" // Ensures proper spacing
          dropdownClass="phone-dropdown" // Custom dropdown style
          disableDropdown={false} // Ensure dropdown is enabled
          disableCountryGuess={false} // Keeps formatting correct
          preferredCountries={["gb", "us", "in"]} // Prioritize common countries
          autoFormat={true} // Format number based on country
        />
      </div>
      {formState.phoneNoError && (
        <span style={{ marginTop: "-2%" }} className="fontName emailErrorMsg">
          {formState.phoneNoError}
        </span>
      )}
    </IonCol>
                   
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <IonSelect interface="popover" className='fontName dark-form-control-model' placeholder="Select Role" value={selectedRole} onIonChange={(e) => setSelectedRole(e.detail.value)}>
                        {roles.map((role) => (
                          <IonSelectOption key={role.id} value={role.id}>{role.value}</IonSelectOption>
                        ))}
                      </IonSelect>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Your Role</label>
                    </div>
                  </IonCol> */}
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputEmail}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Email<span className="span-star-clr">*</span></label>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" col-12 onClick={submitUserDetails}>
                      <IonButton expand="block" className="booking-details-btn">
                        Save
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <div onClick={focusOut} className="dark-v-profile-td-card animate__animated animate__fadeInUp animate__slow ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonLabel className="dark-login-edit-title">Legal Information</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputLBname}
                          onChange={e => handleLBnameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Legal Business Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.LBnameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBnameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputLBtradeName}
                          onChange={e => handleLBtradeNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Trading Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.LBtradeNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBtradeNameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect interface="popover" className='fontName dark-form-control-model' placeholder="Select Bussiness Type--" value={selectedBD} onIonChange={(e) => setSelectedBD(e.detail.value)}>
                          {businessDropdown.map((businessDropdown) => (
                            <IonSelectOption key={businessDropdown.id} value={businessDropdown.id}>{businessDropdown.value}</IonSelectOption>
                          ))}
                        </IonSelect>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Business Type<span className="span-star-clr">*</span></label>
                      </div>
                    </IonCol>
                    <IonCol size="12">
  <div className="model-container">
    <input
      type="tel"
      className="dark-form-control-model fontName"
      value={inputCompanyNo}
      onChange={handleCompanyNoInputChanges}
    />
    <label className="dark-label-outline-model" htmlFor="textAreaExample">
      Company Number<span className="span-star-clr">*</span>
    </label>
  </div>
  {formState.companyNoError && (
    <span
      style={{ marginTop: "-2%" }}
      className="fontName emailErrorMsg"
    >
      {formState.companyNoError}
    </span>
  )}
</IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputBemail}
                          onChange={e => handleBemailInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Business Email Adress<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.bEmailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bEmailError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputAddressOne}
                          onChange={e => handleAddressOneInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        // onDrop={focusOut}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Postcode<span className="span-star-clr">*</span></label>
                      </div>
                      {/* <div className="dropdown"> */}
                      {postcodeList !== null &&
                        <div className={postcodeList.length == 0 || latestInputAddressOneValue.current !== '' || blurDropDown == false ? "dropdown-content" : "dark-dropdown-content-avail"}>
                          {postcodeList.map((post: any, index: any) => (
                            <IonItem className="post-code-mt fontName dark-item" key={index} onClick={() => { handleOptionClick(post.postcode); setPClong(post.longitude); setPClat(post.latitude); }}>
                              {post.postcode}
                            </IonItem>
                          ))}
                        </div>
                      }
                      {postcodeList == null &&
                        <div className={blurDropDown == false ? "dropdown-content" : "dark-dropdown-content-avail-null"}>
                          <h6 className="fontName" style={{ marginTop: "15%", color: "#CCCCCC", textAlign: "center" }}>No results found</h6>
                        </div>
                      }
                      {/* </div> */}
                      <span className='fontName emailErrorMsg'>{postcodeError}</span>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model fontName'
                          value={inputBwebsite}
                          onChange={e => handleBwebsiteInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Website</label>
                      </div>
                      {formState.bWebsiteError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bWebsiteError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          type="tel"
                          className='dark-form-control-model fontName'
                          value={inputBnumber}
                          onChange={e => handleBnumberInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model" htmlFor="textAreaExample">Phone Number<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.bNumberError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bNumberError}</span>}
                    </IonCol>
                   
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputAddressTwo}
                        onChange={e => handleAddressTwoInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Adress Line 2</label>
                    </div>
                    {formState.addressTwoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.addressTwoError}</span>}
                  </IonCol> */}
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputTown}
                        onChange={e => handleTownInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Town / City</label>
                    </div>
                    {formState.townError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.townError}</span>}
                  </IonCol> */}
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='dark-form-control-model fontName'
                        value={inputCountry}
                        onChange={e => handleCountryInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="dark-label-outline-model" htmlFor="textAreaExample">Country</label>
                    </div>
                    {formState.countryError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.countryError}</span>}
                  </IonCol> */}
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonButton expand="block" onClick={submitLegalDetails} className="booking-details-btn">
                        Save
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <div onClick={focusOut} className="dark-v-profile-last-card animate__animated animate__fadeInUp animate__slow ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol col-12 size="12">
                      <IonLabel className="dark-login-edit-title">Assigned Categories</IonLabel>
                    </IonCol>
                    {serviceAssigned.slice(0, 9).map((service) => (
                      <IonCol col-4 size="4">
                        <h6 className="service-cat-chip">{service.title}</h6>
                      </IonCol>
                    ))}
                    {serviceAssigned.length > 9 &&
                      <IonCol col-12 size="12">
                        <IonButton
                          routerLink="/View-Categories"
                          routerDirection="none"
                          className="fontName dark-view-more"
                        >
                          View more
                        </IonButton>
                        {/* <h6 className="fontName view-more"></h6> */}
                      </IonCol>
                    }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => { setShowSuspendedModal(false) }}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />

          </IonContent>
        </>
      }
      {networkStatus == false && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="no-network-div center-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="profile-header-toolbar center-div">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrowWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ color: "#fff" }} className="ion-text-center">Edit Profile</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="profile">
            <div className="center-div">
              <div className="background-holder"></div>
              <div onClick={focusOut} className="profile-top-card profile-top-card animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonLabel className="login-edit-title">User / Login Details</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputFirstName}
                          onChange={e => handleFirstNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">First Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.firstNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.firstNameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputTradingName}
                          onChange={e => handleTradingNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Surname<span className="span-star-clr">*</span></label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          type="tel"
                          className='form-control-model fontName'
                          value={inputPhoneNo}
                          onChange={e => handlePhoneNoInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Phone Number<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.phoneNoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.phoneNoError}</span>}
                    </IonCol>
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <IonSelect interface="popover" className='fontName form-control-model' placeholder="Select Role" value={selectedRole} onIonChange={(e) => setSelectedRole(e.detail.value)}>
                        {roles.map((role) => (
                          <IonSelectOption key={role.id} value={role.id}>{role.value}</IonSelectOption>
                        ))}
                      </IonSelect>
                      <label className="label-outline-model" htmlFor="textAreaExample">Your Role</label>
                    </div>
                  </IonCol> */}
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputEmail}
                          onChange={e => handleEmailInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Email<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.emailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.emailError}</span>}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" col-12 onClick={submitUserDetails}>
                      <IonButton expand="block" className="booking-details-btn">
                        Save
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <div onClick={focusOut} className="v-profile-td-card animate__animated animate__fadeInUp animate__slow ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonLabel className="login-edit-title">Legal Information</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputLBname}
                          onChange={e => handleLBnameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Legal Business Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.LBnameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBnameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputLBtradeName}
                          onChange={e => handleLBtradeNameInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Trading Name<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.LBtradeNameError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.LBtradeNameError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <IonSelect interface="popover" className='fontName form-control-model' placeholder="Select Bussiness Type--" value={selectedBD} onIonChange={(e) => setSelectedBD(e.detail.value)}>
                          {businessDropdown.map((businessDropdown) => (
                            <IonSelectOption key={businessDropdown.id} value={businessDropdown.id}>{businessDropdown.value}</IonSelectOption>
                          ))}
                        </IonSelect>
                        <label className="label-outline-model" htmlFor="textAreaExample">Business Type<span className="span-star-clr">*</span></label>
                      </div>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          type="tel"
                          className='form-control-model fontName'
                          value={inputCompanyNo}
                          onChange={e => handleCompanyNoInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Company Number<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.companyNoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.companyNoError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputBemail}
                          onChange={e => handleBemailInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Business Email Adress<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.bEmailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bEmailError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputAddressOne}
                          onChange={e => handleAddressOneInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        // onDrop={focusOut}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Postcode<span className="span-star-clr">*</span></label>
                      </div>
                      {/* <div className="dropdown"> */}
                      {postcodeList !== null &&
                        <div className={postcodeList.length == 0 || latestInputAddressOneValue.current !== '' || blurDropDown == false ? "dropdown-content" : "dropdown-content-avail"}>
                          {postcodeList.map((post: any, index: any) => (
                            <IonItem className="post-code-mt fontName" key={index} onClick={() => { handleOptionClick(post.postcode); setPClong(post.longitude); setPClat(post.latitude); }}>
                              {post.postcode}
                            </IonItem>
                          ))}
                        </div>
                      }
                      {postcodeList == null &&
                        <div className={blurDropDown == false ? "dropdown-content" : "dropdown-content-avail-null"}>
                          <h6 className="fontName" style={{ marginTop: "15%" }}>No results found</h6>
                        </div>
                      }
                      {/* </div> */}
                      <span className='fontName emailErrorMsg'>{postcodeError}</span>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model fontName'
                          value={inputBwebsite}
                          onChange={e => handleBwebsiteInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Website</label>
                      </div>
                      {formState.bWebsiteError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bWebsiteError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                        type="tel"
                          className='form-control-model fontName'
                          value={inputBnumber}
                          onChange={e => handleBnumberInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                          minLength={10}
                          maxLength={10}
                        ></input>
                        <label className="label-outline-model" htmlFor="textAreaExample">Phone Number<span className="span-star-clr">*</span></label>
                      </div>
                      {formState.bNumberError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.bNumberError}</span>}
                    </IonCol>
                    {/* {formState.addressOneError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.addressOneError}</span>} */}

                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='form-control-model fontName'
                        value={inputAddressTwo}
                        onChange={e => handleAddressTwoInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="label-outline-model" htmlFor="textAreaExample">Adress Line 2</label>
                    </div>
                    {formState.addressTwoError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.addressTwoError}</span>}
                  </IonCol> */}
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='form-control-model fontName'
                        value={inputTown}
                        onChange={e => handleTownInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="label-outline-model" htmlFor="textAreaExample">Town / City</label>
                    </div>
                    {formState.townError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.townError}</span>}
                  </IonCol> */}
                    {/* <IonCol size="12" col-12>
                    <div className="model-container">
                      <input
                        className='form-control-model fontName'
                        value={inputCountry}
                        onChange={e => handleCountryInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      ></input>
                      <label className="label-outline-model" htmlFor="textAreaExample">Country</label>
                    </div>
                    {formState.countryError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.countryError}</span>}
                  </IonCol> */}
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonButton expand="block" onClick={submitLegalDetails} className="booking-details-btn">
                        Save
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              {/* <IonLabel className="login-edit-title">Services Assigned</IonLabel> */}
              <div onClick={focusOut} className="v-profile-last-card animate__animated animate__fadeInUp animate__slow ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol col-12 size="12">
                      <IonLabel className="login-edit-title">Assigned Categories</IonLabel>
                    </IonCol>
                    {serviceAssigned.slice(0, 9).map((service) => (
                      <IonCol col-4 size="4">
                        <h6 className="service-cat-chip">{service.title}</h6>
                      </IonCol>
                    ))}
                    {serviceAssigned.length > 9 &&
                      <IonCol col-12 size="12">
                        <IonButton
                          routerLink="/View-Categories"
                          routerDirection="none"
                          className="fontName view-more"
                        >
                          View more
                        </IonButton>
                        {/* <h6 className="fontName view-more"></h6> */}
                      </IonCol>
                    }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false) }} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Viewprofile;
