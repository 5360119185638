import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
  IonToolbar,
  IonItem,
  useIonViewWillEnter,
  useIonViewDidEnter
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import {apiConfig, axiosInstance} from "../../apiConfig";

import "./Signup.css";
// import AddressAutocomplete from "../../components/Mapcomponent";

const Signup: React.FC = () => {


  const history = useHistory();
  const [formData, setFormData] = useState<{
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    mobile: string;
    website: string | null;
    postcode: string;
    category: string[];
    fcmtoken?: string; // Mark fcmtoken as optional
  }>({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    fcmtoken: localStorage.getItem("fcmToken") || undefined,
    mobile: "",
    website: null,
    postcode: "",
    category: [],
  });

  const [showPassword, setShowPassword] = useState(false);
  const [resetInput, setResetInput] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [networkStatus, setNetworkStatus] = useState<any>(true);

  const [phoneError, setPhoneError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState("");
  const [os, setOS] = useState('other');

  const year = new Date().getFullYear();

  const [scrollTrigger, setScrollTrigger] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [servicesList, setServicesList] = useState<Array<any>>([]);
  const [postcodeList, setPostcodeList] = useState<Array<any>>([]);
  const [postcodeError, setPostcodeError] = useState('');

  const [inputBwebsite, setInputBwebsite] = useState('');
  const latestInputBwebsiteValue = useRef('');

  const [inputAddressOne, setInputAddressOne] = useState('');
  const latestInputAddressOneValue = useRef('');

  const [blurDropDown, setBlurDropDown] = useState(false);
  const [PClong, setPClong] = useState('');
  const [PClat, setPClat] = useState('');

  const selectedService = useRef('');


  function getPlatforms() {
    // Simple check for desktop or mobile platform
    if (navigator.userAgent.indexOf('Mobi') > -1) {
        return "mobile";
    } else {
        return "desktop";
    }
}

    useIonViewWillEnter(() => {
      resetForm();
    }, []);

    useEffect(() => {
      if (scrollTrigger) {
        setTimeout(() => {
          contentRef.current?.scrollToTop(300);
          window.scrollTo({ top: 0, behavior: 'smooth' }); // fallback
        }, 100);
        setScrollTrigger(false);
      }
    }, [scrollTrigger]);

  useEffect(() => {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macosPlatforms.includes(platform)) {
      setOS('macos');
    } else if (windowsPlatforms.includes(platform)) {
      setOS('windows');
    }

    getServices();
    resetForm();

  }, []);


  const goBack = () => {
    resetForm();
    history.push('/login');
  };

  const resetForm = () => {
    setFormData((prevFormData) => ({
      ...prevFormData, // Preserve the current fcmtoken value
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      website: null,
      postcode: "",
      category: [],
    }));
  
    setIsChecked(false);
    setEmailError('');
    setPasswordError('');
    setPhoneError('');
    setServiceError('');
    setResetInput(true);
  };

  const handleAddressOneInputChanges = (event: any) => {
    latestInputAddressOneValue.current = '';
    setPClong('');
    setPClat('');
    setInputAddressOne(event.target.value);
    getPostCode(event.target.value);
    setBlurDropDown(true);
  }

  const handleOptionClick = (option: string) => {
    setBlurDropDown(true);
    console.log("option", option);
    setInputAddressOne(option);
    latestInputAddressOneValue.current = option

    setFormData((prevData) => ({
      ...prevData,
      postcode: option,
    }));

  };

  const handleBwebsiteInputChanges = (event: any) => {
    const bWebsite = event.target.value;
    setInputBwebsite(bWebsite);
    latestInputBwebsiteValue.current = bWebsite;
  

    setFormData((prevData) => ({
      ...prevData,
      website: bWebsite,
    }));
  };

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChanges = (field: string, value: string) => {
    if (field === "email" || field === 'password') {
      value = value.replace(/\s+/g, '');
    }

    setFormData({
      ...formData,
      [field]: value,
    });

    if (field === "email") {
      setEmailError('');
    }

    if (field === 'password') {
      setPasswordError('');
    }

    if (field === 'mobile') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

    if (field === 'mobile') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

  };

  const handleServiceChange = (event: CustomEvent) => {
    const selectedServices = event.detail.value;
    console.log("selectedServices",selectedServices);
    selectedService.current = selectedServices
    setFormData((prevData) => ({
      ...prevData,
      category: selectedServices,
    }));
    setServiceError("");
  };

  const getPostCode = (postcode: any) => {
    // setLoading(true);
    // setBackdropVisible(true);
    if (postcode) {
      setPostcodeError('');
      axiosInstance.get(`https://api.postcodes.io/postcodes?q=${postcode}&format=json`)
        .then(response => {
          console.log("response", response);
          setPostcodeList(response.data.result)
        })
        .catch(error => {
          console.log(error);
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          // setLoading(false);
          // setBackdropVisible(false);
        });
    } else {
      setPostcodeList([]);
      setPostcodeError("Please Enter a valid postcode")
    }
  }

  const getServices = () => {

    // setLoading(true);
    // setBackdropVisible(true);

    axiosInstance.post(`https://afh.cloud/api/categoriesautocomplete?q=`, {
      headers: {
        // 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.suspendstatus === false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
        } else {
          setServicesList(res.data);
        }
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const createAccount = () => {
    let emailValid = true;
    let passwordValid = true;
    let phoneValid = true;
    let addressValid = true;

    if (formData.email !== "" && formData.firstName !== "" && formData.lastName !== "" && formData.companyName !== "" && formData.password !== "" && formData.mobile !== "") {
      if (formData.email === "") {
        setEmailError("Invalid Email address");
        emailValid = false;
      } else {
        setEmailError("");
      }

      if (formData.password === "") {
        setPasswordError("Password is required");
        passwordValid = false;
      } else {
        setPasswordError("");
      }

      if(selectedService.current == ''){
        setServiceError('Please select alteast one services')
      }

      const isValidPhoneNumber = formData.mobile.length === 11 && formData.mobile.startsWith('0');
      if (!isValidPhoneNumber) {
        setPhoneError(
          formData.mobile.length !== 11
            ? 'Phone number should be 11 digits long'
            : 'Phone number should start with 0'
        );
        phoneValid = false;
      } else {
        setPhoneError('');
      }

      if (latestInputAddressOneValue.current === "") {
        setErrorMessage("Please select the postcode from the dropdown");
        addressValid = false;
      }

      if (emailValid && passwordValid && phoneValid && addressValid) {
        setLoading(true);
        setBackdropVisible(true);
        const endpoint = "/signup"

        const { fcmtoken, ...otherFormData } = formData;
        const payload = fcmtoken ? { ...otherFormData, fcmtoken } : { ...otherFormData };
        

        axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, payload, { headers: apiConfig.getHeaders(endpoint) })
          .then(response => {
            if (response.data.suspendstatus === false) {
              setSupendedMsg(response.data.message);
              setSupendedTitle(response.data.title);
              setShowSuspendedModal(true);
            } else if (response.data.status === true) {
              localStorage.setItem("name", response.data.data.name);
              localStorage.setItem("tradingName", response.data.data.ownername);
              localStorage.setItem("companyName", response.data.data.companyname);
              localStorage.setItem("email", response.data.data.email);
              localStorage.setItem("displaypicture", response.data.data.displaypicture);
              localStorage.setItem("mobile", response.data.data.mobile);
              localStorage.setItem("postcode", response.data.data.postcode);
              localStorage.setItem("tokenType", response.data.data.token_type);
              localStorage.setItem("accessToken", response.data.data.accessToken);
              localStorage.setItem("expensesInfo", "info");
              localStorage.setItem("servicesViewed","active");
              localStorage.setItem("newUser", "yes");
              localStorage.setItem("fromSignup", "yes");
              localStorage.setItem("isLoggedIn","yes");
              localStorage.setItem("loggedOut","unsuccesful")
              setShowToastSuccess(true);
              setSuccessMessage(response.data.message);
              resetForm();
              setResetInput(true);
              setShowPassword(false);
              setLoading(false);
              setBackdropVisible(false);
      
              history.push("/tabs/dashboard");
            } else {
              setShowErrorToast(true);
              setErrorMessage(response.data.message);
              setLoading(false);
              setBackdropVisible(false);
            }
            setTimeout(() => {
              if (loading === true) {
                setLoading(false);
                setBackdropVisible(false);
                setErrorMessage("Something went wrong on API please try again");
                setShowErrorToast(true);
              }
            }, 10000);
          })
          .catch(error => {
            if (error.code === "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });

      } else {
        if (!emailValid) {
          setErrorMessage("Please enter a valid email");
        } else if (!passwordValid) {
          setErrorMessage("Please enter a valid password");
        } else if (!phoneValid) {
          setErrorMessage("Please enter a valid Phone Number");
        }
        setShowErrorToast(true);
      }
    } else {
      setErrorMessage("Please enter the required fields");
      setShowErrorToast(true);
    }
  };

  return (
    <IonPage>
      {/* {getPlatforms() == "mobile" && (
      <IonHeader className="ion-no-border">
        <IonToolbar className="light-mode-header-toolbar signup-toolbar">
          <IonButtons slot="start">
            <IonButton
              className="end-btn"
              onClick={goBack}
            >
              <IonIcon
                className="fav header-icons"
                src="assets/imgs/icons/backArrow.svg"
                slot="end" />
            </IonButton>
          </IonButtons>
          <IonTitle style={{ textAlign: "center" }}>
            Signup Here
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      )} */}
      {/* {getPlatforms() == "desktop" && ( */}
                <IonHeader className="">
                    <IonToolbar className="lnd-header">
                        <div className="lnd-flx">
                        <IonImg className="lnd-header-img" src="assets/imgs/images/afh-logo-landscape-white.svg"></IonImg>
                        <div className="m-d-none lnd-btns-div">
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/sign-up"
                        routerDirection="none"
                        style={{ marginRight: "5%" }}
                        >Join us</IonButton>
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/login"
                        routerDirection="none">Login</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
    {/* )} */}
      <IonContent ref={contentRef} className="signup-bg-content ion-padding-horizontal" fullscreen>
        <div className="bg-signup">
        <div className="gradient-overlay t-d-none m-d-none"></div>
        <div className={`${os} the-form signup-grid`}>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
              <IonButton 
                expand="block" 
                routerLink="/login"
                routerDirection="none"
                className="sign-up-btn">
                Already have an account?
              </IonButton>
              </IonCol>
              <IonCol size="12">
                <IonLabel className="top-title m-d-none t-d-none d-title">Become a Partner</IonLabel>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={e => handleInputChanges('companyName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Company Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Email"
                    value={formData.email}
                    onChange={e => handleInputChanges('email', e.target.value)}
                  ></input>
                  {emailError && (<span className='fontName emailErrorMsg'>{emailError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Email<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={e => handleInputChanges('firstName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">First Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={e => handleInputChanges('lastName', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Last Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>

              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className="form-control-model-fp-signup fontName"
                    placeholder="Phone"
                    value={formData.mobile}
                    minLength={11}
                    maxLength={11}
                    onChange={(e) => handleInputChanges('mobile', e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Phone Number<span style={{ color: '#FF0000' }}>*</span></label>
                  {phoneError && <p className='fontName emailErrorMsg'>{phoneError}</p>}
                </div>
              </IonCol>

              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    style={{ background: "rgba(255, 255, 255, 0)" }}
                    className='form-control-model-fp-signup fontName'
                    placeholder="Password"
                    value={formData.password}
                    onChange={e => handleInputChanges('password', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  />
                  {/* <IonIcon
                    className='eye-icon eye-icon-signup'
                    onClick={handleTogglePassword}
                    icon={showPassword ? eyeOffOutline : eyeOutline}
                  /> */}
                  {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="password">Password<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>

              <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp-signup fontName'
                          value={inputAddressOne}
                          onChange={e => handleAddressOneInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                          placeholder="Postcode"
                        // onDrop={focusOut}
                        ></input>
                        <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Postcode<span className="span-star-clr">*</span></label>
                      </div>
                      {/* <div className="dropdown"> */}
                      {postcodeList !== null &&
                        <div className={postcodeList.length == 0 || latestInputAddressOneValue.current !== '' || blurDropDown == false ? "dropdown-content" : "dropdown-content-avail"}>
                          {postcodeList.map((post: any, index: any) => (
                            <IonItem className="fontName" key={index} onClick={() => { handleOptionClick(post.postcode); setPClong(post.longitude); setPClat(post.latitude); }}>
                              {post.postcode}
                            </IonItem>
                          ))}
                        </div>
                      }
                      {postcodeList == null &&
                        <div className={blurDropDown == false ? "dropdown-content" : "dropdown-content-avail-null"}>
                          <h6 className="fontName" style={{ marginTop: "20%" }}>No results found</h6>
                        </div>
                      }
                      {/* </div> */}
                      <span className='fontName emailErrorMsg'>{postcodeError}</span>
                    </IonCol>

                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp-signup fontName'
                          value={inputBwebsite}
                          placeholder="https://example.com/"
                          onChange={e => handleBwebsiteInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Website</label>
                      </div>
                    </IonCol>

              <IonCol size="12">
              <div className="model-container-signup">
              <IonSelect className='sign-up-services-input form-control-model-fp-signup fontName' interface="popover" placeholder="Select Services" multiple={true} onIonChange={handleServiceChange}>
                  {servicesList && servicesList.map((ser: any) => (
                     <IonSelectOption key={ser.id} value={ser.id}>{ser.title}</IonSelectOption>
                  ))}
            </IonSelect>
            <label className="label-outline-model-fp-signup" htmlFor="services">Services<span style={{ color: '#FF0000' }}>*</span></label>
            {serviceError && <p className='fontName emailErrorMsg'>{serviceError}</p>}
            </div>
                  </IonCol>
                    <IonCol size='12' col-12>
                        <IonButton expand="block" onClick={createAccount} className="bottom-btn-sign-up login-button">
                          Signup
                        </IonButton>
                    </IonCol>
            </IonRow>
          </IonGrid>
            </div>
        </div>
           <IonGrid className='footer-grid-cmpy'>
                      <IonRow className="lnd-mrgn-row">
                          <IonCol size='12'>
                              <IonLabel className='footer-hire-cmpy'>DO YOU OWN A HIRE COMPANY?</IonLabel>
                          </IonCol>
                          <IonCol size='12'>
                              <div className="d-flex-sign-up">
                                  <IonLabel className='ftr-jn-our-ntwrk-p '>Get guaranteed bookings - or nothing to pay (yes you read that right). Click here to find out more:</IonLabel>
                                  <IonButton 
                                    className='ftr-jn-our-ntwrk-btn' 
                                    expand="block"
                                    onClick={() => {
                                      setScrollTrigger(true);
                                    }}
                                  >
                                    JOIN OUR NETWORK: LEARN MORE
                                  </IonButton>
                              </div>
                          </IonCol>
                      </IonRow>
                  </IonGrid>
                  <IonGrid className="footer-toolbar">
                          <IonRow className="lnd-mrgn-row">
                              {/* FOR CUSTOMERS */}
                              <IonCol size="12" size-md="6" size-lg="2">
                                  <h4 className='ftr-title'>FOR CUSTOMERS</h4>
                                  <ul className="footer-links">
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/hire" title="Hire Something">
                                              Hire Something
                                          </a>
                                      </li>
                                      {/* <li>
                                          <button className="link-button">Already had a quote?</button>
                                      </li> */}
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/news/" title="Hire & Rental News">
                                              Hire & Rental News
                                          </a>
                                      </li>
                                  </ul>
                              </IonCol>
          
                              {/* FOR PARTNERS */}
                              <IonCol size="12" size-md="6" size-lg="2">
                                  <h4 className='ftr-title'>FOR PARTNERS</h4>
                                  <ul className="footer-links">
                                      <li>
                                          <a className='ftr-a'
                                              href="/sign-up"
                                              title="Sign up"
                                          >
                                              Sign up
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a' href="/login" title="Login">
                                              Login
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a'
                                              href="https://anythingforhire.com/about#how-it-works"
                                              title="How it works"
                                          >
                                              How it works
                                          </a>
                                      </li>
                                  </ul>
                              </IonCol>
          
                              {/* LEGAL STUFF */}
                              <IonCol size="12" size-md="6" size-lg="2">
                                  <h4 className='ftr-title'>LEGAL STUFF</h4>
                                  <ul className="footer-links">
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/terms" title="Terms">
                                              Terms
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/privacy" title="Privacy">
                                              Privacy
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/cookies" title="Cookies">
                                              Cookies
                                          </a>
                                      </li>
                                  </ul>
                              </IonCol>
          
                              {/* POPULAR SERVICES */}
                              <IonCol size="12" size-md="6" size-lg="3">
                                  <h4 className='ftr-title'>POPULAR SERVICES</h4>
                                  <ul className="footer-links">
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/hire/limo-hire" title="Limo Hire">
                                              Limo Hire
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a'
                                              href="https://anythingforhire.com/hire/bouncy-castle-hire"
                                              title="Bouncy Castle Hire"
                                          >
                                              Bouncy Castle Hire
                                          </a>
                                      </li>
                                      <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/hire/coach-hire" title="Coach Hire">
                                              Coach Hire
                                          </a>
                                      </li>
                                  </ul>
                              </IonCol>
          
                              {/* CONNECT WITH US */}
                              <IonCol size="12" size-md="6" size-lg="3">
                                  <h4 className='ftr-title'>CONNECT WITH US</h4>
                                  <ul className="social-links">
                                  <li>
                                          <a className='ftr-a' href="https://anythingforhire.com/contact" title="Contact Us">
                                              Contact Us
                                          </a>
                                      </li>
                                      </ul>
                                      <ul className='social-m-icon-div social-links'>
                                      <li>
                                          <a
                                              href="https://www.facebook.com/AnythingForHire"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              title="Facebook"
                                          >
                                              <img
                                                  className="social-m-icon"
                                                  src="https://anythingforhire.com/img/facebook.svg"
                                                  alt="Facebook" />
                                          </a>
                                      </li>
                                      <li >
                                          <a
                                              href="https://twitter.com/anythingforhire?lang=en"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              title="Twitter"
                                          >
                                              <img
                                                  className="social-m-icon"
                                                  src="/assets/imgs/icons/twitter.svg"
                                                  alt="Twitter" />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="https://www.instagram.com/anythingforhire_com" target="_blank" rel="nofollow" title="Anything for Hire Instagram">
                                              <img className="social-m-icon" src="/assets/imgs/icons/instagram.svg" alt="Anything for Hire Instagram" title="Anything for Hire Instagram" />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="https://in.linkedin.com/company/anything-for-hire" target="_blank" rel="nofollow noreferrer noopener" title="Anything for Hire Linkedin">
                                              <img className="social-m-icon" src="/assets/imgs/icons/linkedin.svg" title="Anything for Hire Linkedin" alt="Anything for Hire Linkedin" />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="https://www.youtube.com/c/AnythingForHire_com" target="_blank" rel="nofollow noreferrer noopener" title="Anything for Hire Youtube">
                                              <img className="social-m-icon" src="/assets/imgs/icons/youtube.svg" title="Anything for Hire Youtube" alt="Anything for Hire Youtube" />
                                          </a>
                                      </li>
                                  </ul>
                                  <div className="ftr-app-btns app-store-buttons">
                                      <a href='https://itunes.apple.com/gb/app/anything-for-hire-partner/id1440776168?mt=8' target="_blank" title="Download Our Partner App for iOS">
                                      <img
                                          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                          alt="Apple App Store"
                                          className="store-button" />
                                      </a>
                                      <a href="https://play.google.com/store/apps/details?id=com.afhapp.afhpartner" target="_blank" title="Download Our Partner App for Android">
                                      <img
                                          src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                          alt="Google Play Store"
                                          className="ftr-play-btn store-button" />
                                          </a>
                                  </div>
                              </IonCol>
                          </IonRow>
                      </IonGrid>
                      <IonGrid className='frt-last-grid'>
                          <IonRow className="lnd-mrgn-row">
                              <IonCol size="12" className="mrgn-row">
                                  <div className="copy-right">
                                      <p className="copyright">Copyright © {year} AFH Ltd. All rights reserved.</p>
                                      {/* <p className="copyright">Built by <a href="https://mobo.co.uk" target="_blank" title="MOBO" className='mobo-word' rel="nofollow noreferrer noopener">MOBO</a> ❤️</p> */}
                                  </div>
                              </IonCol>
                          </IonRow>
                          </IonGrid>
        <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
          <IonContent className="model-bg">
            <IonGrid className='cnfmModelGrid'>
              <IonRow>
                <IonCol size="12" col-12>
                  <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonButton
                    routerLink="/login"
                    routerDirection="none"
                    style={{ width: "100%", margin: "auto" }} 
                    className='fontName booking-details-btn' 
                    onClick={() => { setShowSuspendedModal(false) }} 
                    expand="block"
                    >
                    Back to Login
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonLoading
          isOpen={loading}
          spinner="circular"
          translucent={true}
        />

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={3000}
          color="danger" 
          />

        <IonToast
          isOpen={showToasSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message={successMessage}
          duration={3000}
          color="success" 
          />

      </IonContent>

    </IonPage >
  );
};

export default Signup;
