import React, { useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';

const OldFooter: React.FC = () => {
    const year = new Date().getFullYear();

    return (

        <>
        <IonGrid className='footer-grid-cmpy'>
            <IonRow className="lnd-mrgn-row">
                <IonCol size='12'>
                    <IonLabel className='footer-hire-cmpy'>DO YOU OWN A HIRE COMPANY?</IonLabel>
                </IonCol>
                <IonCol size='12'>
                    <div className="d-flex-sign-up">
                        <IonLabel className='ftr-jn-our-ntwrk-p '>Get guaranteed bookings - or nothing to pay (yes you read that right). Click here to find out more:</IonLabel>
                        <IonButton className='ftr-jn-our-ntwrk-btn' 
                        routerLink="/sign-up"
                        routerDirection="none" expand="block">JOIN OUR NETWORK: LEARN MORE</IonButton>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
        <IonGrid className="footer-toolbar">
                <IonRow className="lnd-mrgn-row">
                    {/* FOR CUSTOMERS */}
                    <IonCol size="12" size-md="6" size-lg="2">
                        <h4 className='ftr-title'>FOR CUSTOMERS</h4>
                        <ul className="footer-links">
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/hire" title="Hire Something">
                                    Hire Something
                                </a>
                            </li>
                            {/* <li>
                                <button className="link-button">Already had a quote?</button>
                            </li> */}
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/news/" title="Hire & Rental News">
                                    Hire & Rental News
                                </a>
                            </li>
                        </ul>
                    </IonCol>

                    {/* FOR PARTNERS */}
                    <IonCol size="12" size-md="6" size-lg="2">
                        <h4 className='ftr-title'>FOR PARTNERS</h4>
                        <ul className="footer-links">
                            <li>
                                <a className='ftr-a'
                                    href="/sign-up"
                                    title="Sign up"
                                >
                                    Sign up
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a' href="/login" title="Login">
                                    Login
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a'
                                    href="https://anythingforhire.com/about#how-it-works"
                                    title="How it works"
                                >
                                    How it works
                                </a>
                            </li>
                        </ul>
                    </IonCol>

                    {/* LEGAL STUFF */}
                    <IonCol size="12" size-md="6" size-lg="2">
                        <h4 className='ftr-title'>LEGAL STUFF</h4>
                        <ul className="footer-links">
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/terms" title="Terms">
                                    Terms
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/privacy" title="Privacy">
                                    Privacy
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/cookies" title="Cookies">
                                    Cookies
                                </a>
                            </li>
                        </ul>
                    </IonCol>

                    {/* POPULAR SERVICES */}
                    <IonCol size="12" size-md="6" size-lg="3">
                        <h4 className='ftr-title'>POPULAR SERVICES</h4>
                        <ul className="footer-links">
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/hire/limo-hire" title="Limo Hire">
                                    Limo Hire
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a'
                                    href="https://anythingforhire.com/hire/bouncy-castle-hire"
                                    title="Bouncy Castle Hire"
                                >
                                    Bouncy Castle Hire
                                </a>
                            </li>
                            <li>
                                <a className='ftr-a' href="https://anythingforhire.com/hire/coach-hire" title="Coach Hire">
                                    Coach Hire
                                </a>
                            </li>
                        </ul>
                    </IonCol>

                    {/* CONNECT WITH US */}
                    <IonCol size="12" size-md="6" size-lg="3">
                        <h4 className='ftr-title'>CONNECT WITH US</h4>
                        <ul className="social-links">
                        <li>
                                <a className='ftr-a' href="https://anythingforhire.com/contact" title="Contact Us">
                                    Contact Us
                                </a>
                            </li>
                            </ul>
                            <ul className='social-m-icon-div social-links'>
                            <li>
                                <a
                                    href="https://www.facebook.com/AnythingForHire"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Facebook"
                                >
                                    <img
                                        className="social-m-icon"
                                        src="https://anythingforhire.com/img/facebook.svg"
                                        alt="Facebook" />
                                </a>
                            </li>
                            <li >
                                <a
                                    href="https://twitter.com/anythingforhire?lang=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Twitter"
                                >
                                    <img
                                        className="social-m-icon"
                                        src="/assets/imgs/icons/twitter.svg"
                                        alt="Twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/anythingforhire_com" target="_blank" rel="nofollow" title="Anything for Hire Instagram">
                                    <img className="social-m-icon" src="/assets/imgs/icons/instagram.svg" alt="Anything for Hire Instagram" title="Anything for Hire Instagram" />
                                </a>
                            </li>
                            <li>
                                <a href="https://in.linkedin.com/company/anything-for-hire" target="_blank" rel="nofollow noreferrer noopener" title="Anything for Hire Linkedin">
                                    <img className="social-m-icon" src="/assets/imgs/icons/linkedin.svg" title="Anything for Hire Linkedin" alt="Anything for Hire Linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/c/AnythingForHire_com" target="_blank" rel="nofollow noreferrer noopener" title="Anything for Hire Youtube">
                                    <img className="social-m-icon" src="/assets/imgs/icons/youtube.svg" title="Anything for Hire Youtube" alt="Anything for Hire Youtube" />
                                </a>
                            </li>
                        </ul>
                        <div className="ftr-app-btns app-store-buttons">
                            <a href='https://itunes.apple.com/gb/app/anything-for-hire-partner/id1440776168?mt=8' target="_blank" title="Download Our Partner App for iOS">
                            <img
                                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                alt="Apple App Store"
                                className="store-button" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.afhapp.afhpartner" target="_blank" title="Download Our Partner App for Android">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Google Play Store"
                                className="ftr-play-btn store-button" />
                                </a>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonGrid className='frt-last-grid'>
                <IonRow className="lnd-mrgn-row">
                    <IonCol size="12" className="mrgn-row">
                        <div className="copy-right">
                            <p className="copyright">Copyright © {year} AFH Ltd. All rights reserved.</p>
                            {/* <p className="copyright">Built by <a href="https://mobo.co.uk" target="_blank" title="MOBO" className='mobo-word' rel="nofollow noreferrer noopener">MOBO</a> ❤️</p> */}
                        </div>
                    </IonCol>
                </IonRow>
                </IonGrid>
            </>
    );
};

export default OldFooter;
